//font-names
$font-regular:   400;
$font-medium:    500;
$font-semibold:  600;
$font-bold:      700;
$font-extra:     800;
$font-black:     900;

$font-family-base:   'Montserrat', Helvetica, Arial, sans-serif;

//media
$desktop-2k:  2400px !default;
$desktop-lg:  1920px !default;
$desktop:     1366px !default;
$laptop:      1024px !default;
$tablet:      768px !default;
$mobile:      375px !default;

// colors
$white:          #fff !default;
$black:          #000 !default;
$black-lt:       #151B26 !default;
$grey:           #636A75 !default;

$purple-dk:      #2C2F49 !default;

$aqua:           #8EDAEA !default;
$aqua-dk:        #71B8CB !default;

$base:           $purple-dk;
$link:           $aqua;

// font sizes
$font-size-big:         48px !default;
$font-size-lg:          38px !default;
$font-size-xlg:         34px !default;
$font-size-xxlg:        32px !default;
$font-size-xxxlg:       26px !default;
$font-size-md:          24px !default;
$font-size-xmd:         22px !default;
$font-size-xxmd:        20px !default;
$font-size-xxxmd:       18px !default;
$font-size-base:        16px !default;
$font-size-sm:          14px !default;
$font-size-xsm:         12px !default;

//container
$padding:               30px !default;
$container-desktop:     (1120px + $padding * 2) !default;

//other
$anim:          .3s;

.page-header-bg {
  position: relative;
  background-image: url("../images/backgrounds/fill01.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: -100px;
  padding-top: 100px;

  @include laptop {
    margin-top: -90px;
    padding-top: 90px;
  }

  &::after {
    @include laptop {
      display: block;
      content: ' ';
      position: absolute;
      width: 60%;
      height: 100%;
      top: 0;
      right: 0;
      background-image: url("../images/backgrounds/fill02.png");
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
    }
  }
}

.page-header {
  padding-bottom: 115px;

  @include desktop {
    padding-top: 180px;
    padding-bottom: 370px;
  }

  .page-header__btn {
    margin-top: 50px;

    @include laptop {
      margin-top: 95px;
    }
  }

  .subtitle {
    font-size: $font-size-xxmd;

    @include laptop {
      font-size: $font-size-md;
    }
  }
}

.page-header-img {
  margin-bottom: 40px;
  padding-top: 30px;

  @include desktop {
    position: absolute;
    width: 70%;
    right: 0;
    top: 145px;
    margin-bottom: 0;
  }

  @include desktop-lg {
    width: 55%;
  }

  .img {
    width: 100%;
  }
}

.listing-group {
  padding-bottom: 120px;

  @include tablet {
    display: flex;
    background-image: url("../images/backgrounds/line.png");
    background-size: 560px 1015px;
    background-position: calc(50% - 80px) 0;
    background-repeat: no-repeat;
  }

  @include laptop {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .listing-group__col {
    @include tablet {
      flex: 1;
    }
  }

  .listing-group__img {
    max-width: 90%;

    @include desktop {
      margin-top: -100px;
      max-width: 100%;
    }
  }
}

.listing {
  @include laptop {
    padding-top: 40px;
  }

  @media screen and (max-width: $tablet) {
    padding-left: $padding;
    padding-right: $padding;
  }

  @media screen and (max-width: $tablet - 1) {
    background-image: url("../images/backgrounds/line-sm.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.listing-item {
  padding-left: 20px;

  @include desktop {
    max-width: 480px;

    &:nth-child(1) {
      margin-left: 89px;
    }

    &:nth-child(2) {
      margin-left: 187px;
    }

    &:nth-child(3) {
      margin-left: 54px;
    }

    &:nth-child(4) {
      margin-left: 2px;
    }

    &:nth-child(5) {
      margin-left: 90px;
    }

    &:nth-child(6) {
      margin-left: -196px;
    }

    &:nth-child(7) {
      margin-left: -346px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 40px;

    @include laptop {
      margin-bottom: 20px;
    }
  }

  .listing-item__title {
    position: relative;

    &::before {
      display: block;
      content: ' ';
      @include size(12px,12px);
      border-radius: 50%;
      background-color: $aqua;
      position: absolute;
      top: 8px;
      left: -22px;
    }
  }

  .listing-item__text {
    @media screen and (max-width: $tablet) {
      font-size: $font-size-base;
    }

    @include desktop {
      height: 88px;
      overflow: hidden;
    }
  }
}

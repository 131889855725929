.footer {
  margin-top: auto;
  background-color: $aqua;
  padding: 60px 0;
  color: $white;
  text-align: center;

  @include laptop {
    padding: 40px 0;
    text-align: left;
  }

  .footer__container {
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .logo {
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 0;
    }
  }
}

.copyright {
  font-weight: $font-semibold;
  font-size: $font-size-xsm;
  margin-top: 20px;

  @include tablet {
    margin-top: 0;
  }
}

.footer-block {
  margin-bottom: 30px;

  @include tablet {
    margin-bottom: 0;
  }

  @include laptop {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: 8px;
  }
}

.footer-menu {
  margin-bottom: 15px;

  @include laptop {
    margin-bottom: 0;
    margin-right: 20px;
  }

  @include desktop {
    margin-right: 40px;
  }

  .footer-menu__item {
    margin-bottom: 10px;

    @include laptop {
      margin-bottom: 0;
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .footer-menu__item-link {
    font-size: $font-size-xsm;
    color: $white;
    vertical-align: text-top;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

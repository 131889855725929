//mobile styles for menu
@media screen and (max-width: $tablet) {
  .nav-main {
    padding: 60px;

    li {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      font-weight: $font-semibold;
      font-size: $font-size-md;
      color: $white;

      &.active {
        &::after {
          display: block;
          content: ' ';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $white;
        }
      }
    }
  }

  .nav-main-primary {
    padding-top: 100px;
  }

  .nav-menu {
    padding-left: 24px;
    padding-right: 24px;
    position: absolute;
    max-height: 0;
    min-height: 0;
    transition: all .3s ease-out;
    overflow-y: scroll;
    background-color: $aqua;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &.open {
      max-height: 100vh;
      min-height: 100vh;
      transition: all .5s ease-in;
    }
  }

  .nav-mobile-btn {
    display: inline-block;
    @include size(38px, 38px);
    text-align: center;
    position: relative;
    margin-right: -12px;
    z-index: 100;

    span {
      display: block;
      @include absolute-center-hor;
      height: 3px;
      width: 24px;
      background-color: $base;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 8px;
      }

      &:nth-child(2) {
        top: 16px;
      }

      &:nth-child(3) {
        top: 24px;
      }
    }

    &.open {
      span {
        background-color: $white;

        &:nth-child(1) {
          transform: translateX(-50%) rotate(45deg);
          top: 18px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateX(-50%) rotate(-45deg);
          top: 18px;
        }
      }
    }
  }
}

//desktop styles for menu
@include tablet-max {
  .nav-main {
    display: flex;
    align-items: center;

    li {
      margin: 0 10px;
    }

    a {
      font-weight: $font-semibold;
      color: $purple-dk;
      font-size: $font-size-base - 1;

      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $aqua-dk;
      }
    }
  }

  .nav-menu {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
  }

  .nav-mobile-btn {
    display: none;
  }
}

@include laptop {
  .nav-menu {
    margin-left: -20px;
    margin-right: -20px;
  }

  .nav-main {
    a {
      padding: 10px;
    }
  }
}

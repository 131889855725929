@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraBold.ttf')  format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.woff') format('woff'),
  url('../fonts/Montserrat-Black.woff2') format('woff2'),
  url('../fonts/Montserrat-Black.ttf')  format('truetype');
  font-weight: 900;
  font-style: normal;
}

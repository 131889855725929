.input-imitation {
  padding-left: 15px;
  padding-right: 15px;

  @include mobile {
    max-width: 365px;
    margin-left: auto;
    margin-right: auto;
  }

  @include laptop {
    max-width: 65%;
    margin-left: 0;
    padding: 0;
    position: relative;
  }

  @include desktop {
    max-width: 70%;
  }

  .form-control {
    position: relative;
    margin: 0 -15px 20px;

    @include laptop {
      margin: 0;
    }
  }

  .focus-control {
    display: block;
    border: 2px solid transparent;
    border-radius: 34px;
    z-index: 0;
    @include absolute-fullsize;
  }

  .form-input {
    position: relative;
    z-index: 1;

    @include laptop {
      padding-right: 300px;
    }

    &:focus {
      & + .focus-control {
        border-color: $white;
      }
    }

    &:invalid {
      box-shadow: none;
    }
  }

  .form-submit {
    @include laptop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
  }
}

.form-input {
  border-radius: 34px;
  background-color: rgba($white, .07);
  height: 68px;
  padding: 20px 40px;
  border: none;
  color: $white;
  width: 100%;
  @include placeholder-color(rgba($white, .5));
  text-align: center;

  @include mobile {
    font-size: $font-size-md;
  }

  @include laptop {
    text-align: left;
  }

  &:focus {
    outline: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: $font-size-sm;
  font-family: $font-family-base;
  font-weight: $font-regular;
  color: $base;
  background-color: $white;
  -webkit-overflow-scrolling: touch;

  &.menu-open,
  &.modal-open {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &,
  &:hover,
  &:focus,
  &:active {
    outline:none;
  }
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

img {
  vertical-align: middle;
  border-style: none;
  @include img-responsive();
}


button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  box-shadow: none;
}

b {
  font-weight: $font-bold;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.container {
  @include container();

  @include desktop {
    max-width: $container-desktop;
  }
}

.full-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include ie {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  @supports not (object-fit: cover) {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.lower-latin {
  list-style: inside lower-latin;
  margin-bottom: 25px;

  li {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-center {
  text-align: center;
}

.text-normal {
  font-style: normal;
}

.text-break {
  word-break: break-word;
}

.text-uppercase {
  text-transform: uppercase;
}

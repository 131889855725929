$button-padding: 23px 30px 20px;

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-size: $font-size-xxmd;
  font-weight: $font-semibold;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  border-radius: 35px;
  transition: box-shadow $anim;
  padding: $button-padding;
  box-shadow: 0 4px 8px rgba($grey, 0.25);
  width: 100%;

  sup {
    font-size: 55%;
    top: -1em;
    font-weight: $font-medium;
  }

  @include tablet {
    max-width: 290px;
  }

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    cursor: pointer;
    box-shadow: none;
  }

  &.btn-block {
    display: block;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
  }

  &.btn-primary {
    color: $white;
    background-color: $aqua;
    border: 1px solid $aqua;

  }

  &.btn-secondary {
    color: $aqua-dk;
    background-color: $white;
    border: 1px solid $white;
  }

  &.btn-flat {
    box-shadow: none;
  }

  &.btn-sm {
    text-transform: none;
    font-size: $font-size-base - 1;
    width: auto;
    padding: 12px 35px;
  }
}

.apps-row {
  display: flex;
  justify-content: center;
  margin-left: -5px;
  margin-right: -5px;

  .apps-row__item {
    width: 162px;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;

    @include laptop {
      width: 200px;
      height: 60px;
    }
  }

  .appstore {
    background-image: url("../images/appstore.png");
  }

  .gplay {
    background-image: url("../images/googleplay.png");
  }
}

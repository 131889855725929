a {
  color: $link;
  text-decoration: none;
  outline: 0;

  &:hover,
  &:focus {
    color: lighten($link, 20%);
  }
}

.link-highlight {
  color: darken($link, 30%);

  &:hover,
  &:focus {
    color: lighten($link, 1%);
  }
}

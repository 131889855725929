.tile {
  margin-bottom: 80px;

  @include tablet {
    margin: 0 -$padding 0;
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include desktop {
    margin-left: -40px;
    margin-right: -40px;
  }

  &:nth-child(odd) {
    .tile__item:nth-child(odd) {
      @include tablet {
        width: 55%;
      }
    }
  }

  &:nth-child(odd) {
    .tile__item:nth-child(even) {
      @include tablet {
        width: 45%;
      }
    }
  }

  &:nth-child(even) {
    .tile__item:nth-child(odd) {
      @include tablet {
        width: 55%;
        order: 2;
      }
    }
  }

  &:nth-child(even) {
    .tile__item:nth-child(even) {
      @include tablet {
        width: 45%;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .tile__item {
    @include tablet {
      padding: 0 $padding;

      .img {
        margin: auto;
      }
    }

    @include desktop {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .img {
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 0;
    }
  }

  .content {
    font-size: $font-size-base;
  }
}
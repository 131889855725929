.header {
  z-index: 1;
  position: relative;

  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

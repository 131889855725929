.mission {
  padding-top: 45px;
  background-image: url("../images/backgrounds/path.png");
  background-repeat: no-repeat;
  background-position: 30px 0;

  @include tablet {
    background-position: 100% 0;
  }

  @include desktop {
    padding-top: 160px;
  }

  .mission__title {
    text-align: center;
  }
}

.section-subtitle {
  font-size: $font-size-base;
  text-align: center;
  margin-bottom: 115px;

  @include tablet {
    max-width: 675px;
    margin: 0 auto 30px;
  }
}

.section-space {
  margin-bottom: 100px;
}

.section-cta {
  background-image: url("../images/backgrounds/bg01.png");
  background-size: cover;
  background-position: 50%;
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-apps {
  @include laptop {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title {
    margin-bottom: 40px;

    @include tablet {
      max-width: 860px;
      margin-left: auto;
      margin-right: auto;
    }

    @include laptop {
      margin-bottom: 30px;
    }
  }

  .subtitle {
    margin-bottom: 40px;
    font-size: $font-size-md;

    @include laptop {
      margin-bottom: 70px;
    }
  }
}

.subscribe {
  font-size: $font-size-base;
  padding-top: 50px;
  padding-bottom: 65px;

  @include laptop {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &--dk {
    color: $white;
    background: $aqua-dk;
    background: linear-gradient(to right, $aqua-dk 0, $aqua 100%);

    .title {
      color: $white;
    }
  }

  .subscribe__container {
    text-align: center;

    @include laptop {
      text-align: left;
      min-height: 266px;
    }
  }

  .subscribe__img {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 50px;

    @include laptop {
      margin: 0;
      width: auto;
      max-width: none;
      position: absolute;
      right: 0;
      bottom: -65px;
    }

    @include desktop {
      right: -55px;
    }
  }

  .title {
    position: relative;

    @include laptop {
      font-size: $font-size-xlg;
    }
  }

  .icon {
    display: none;
    width: 58px;
    order: 5;
    margin: 50px auto 0;

    @include laptop {
      display: block;
      order: 0;
      margin: 0 0 25px;
    }

    .img {
      width: 100%;
    }
  }

  .form-icon-order {
    display: flex;
    flex-direction: column;
  }
}

.subscribe-confirm {
  @include desktop-lg {
    padding-top: 100px;
  }
}

.page-title {
  padding-top: 30px;

  @include laptop {
    padding-top: 40px;
  }
}

.space-btm {
  padding-bottom: 60px;

  @include laptop {
    padding-bottom: 100px;
  }
}

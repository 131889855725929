/*------------- headings ---------------*/

.large,
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $black;
  margin: 0;
  padding: 0;
  line-height: 1.2;

  &.light {
    color: $white
  }

  .emphasis {
    text-transform: uppercase;
    color: $aqua-dk;
  }
}

h1, .h1 {
  margin: 0;
  margin-bottom: 20px;
  font-weight: $font-semibold;
  font-size: $font-size-lg;
  color: $black-lt;

  @include laptop {
    font-size: $font-size-big;
  }
}

h2, .h2 {
  margin: 0;
  margin-bottom: 20px;
  font-weight: $font-semibold;
  font-size: $font-size-xxlg;
  color: $black-lt;

  @include laptop {
    font-size: $font-size-big;
  }
}

h3, .h3 {
  margin: 0;
  margin-bottom: 25px;
  font-weight: $font-semibold;
  font-size: $font-size-md;

  @include laptop {
    font-size: $font-size-xlg;
  }
}

h4, .h4 {
  margin: 0;
  margin-bottom: 20px;
  font-weight: $font-semibold;
  font-size: $font-size-md;

  @include laptop {
    font-size: $font-size-md;
  }
}
